import React, { Component } from "react";
import CabPage from "../CabPage.js";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import { FormataValor, FormataData } from "../k_functions.js";

function TopTabRelVen(k)
{
  return (
    <thead key={3*k+1}>
      <tr key={k}>
        <th width="35%">Produto</th>
        <th width="25%">Unid</th>
        <th width="10%">Q&nbsp;Ven<br/>Q&nbsp;Bon</th>
        <th width="15%">$Total</th>
        <th width="15%">$Medio</th>
      </tr>
    </thead>
  )
}

class VendasRelFechRes extends Component {
  state = {
    aVendasCli: [], // total de vendas por cliente
    aVendasCPU: [], // total de vendas por cliente, produto e unidade
    aVendasTot: [], // total de vendas conforme filtros selecionados
    aPenMesAnt: [], // pendencias de meses anteriores
    aTotPeMAnt: [], // total de pendencias de meses anteriores
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
//    console.log(this.props);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Vendas/VendasRelat.php",
        func_trata: "VendasRelat",
        dat_ini: this.props.dIni,
        dat_fim: this.props.dFim,
        cod_pro: this.props.cPro,
        cod_cli: this.props.cCli,
      }
    })
    .then((response) => {
      //alert(response.data);
//      console.log(response.data);
      this.setState({ aVendasCli: response.data[0] });
      this.setState({ aVendasCPU: response.data[2] });
      this.setState({ aVendasTot: response.data[6] });
      this.setState({ aPenMesAnt: response.data[7] });
      if (this.props.cPMA === "S")
        this.setState({ aTotPeMAnt: response.data[8] });
      else
        this.setState({ aTotPeMAnt: 0 });

//      console.log(this.state.aPenMesAnt);
//      console.log(this.state.aPenMesAnt.length);
//      console.log(this.props.cPMA);
//      console.log(this.props);
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  render() {
    // console.log(this.state.aVendasCli.shift());
//    console.log(this.props);
    return (
      <>
        <CabPage  titulo="Relatório de Fechamento" 
                  subtitulo={"Período das vendas: " + FormataData(this.props.dIni) + " - " + FormataData(this.props.dFim)}
                  paginavoltar="/venrfe" 
                  classSubTit="SubTitRel" />
        {this.state.aVendasCli.length === 0 && 
          <Alert variant="warning">Não há vendas para listar !</Alert>
        }
        {this.state.aVendasCli.length > 0 && this.props.cTCl === "S" && 
          <>
            <b>Total por Cliente</b>
            <Table striped bordered hover>
              <thead>
                <tr key={100}>
                  <th width="50%">Cliente</th>
                  <th width="50%">$Total</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aVendasCli.map((oVenda, index) => (
                  <>
                    <tr key={3000+index}>
                      <td>{oVenda.fantasia} ({oVenda.id_cliente})</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aVendasCli.length > 0 && this.props.cTCP === "S" && 
          <>
            <b>Total por Cliente, Produto e Unidade</b>
            <Table striped bordered hover>
              <TopTabRelVen k={600} />
              <tbody>
              {
                  this.state.aVendasCPU.map((oVenda, index) => (
                  <>
                    {(index === 0 || oVenda.id_cliente !== this.state.aVendasCPU[index-1].id_cliente) ? 
                      <>
                        {index > 0 ? <tr key={10000+index}><td colSpan={5}>{/*Total: {1} */}</td></tr> : ""}
                        <tr key={1000+index}><td colSpan={5}><b>{oVenda.fantasia} ({oVenda.id_cliente})</b></td></tr>
                      </> 
                    : ""}
                    <tr key={2000+index}>
                      <td>{oVenda.desc_produto}</td>
                      <td>{oVenda.desc_unidade}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.QtdVen))}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen))}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen / oVenda.QtdVen))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aPenMesAnt.length > 0 && this.props.cPMA === "S" && 
          <>
            <b>Parcelas em aberto até a data de hoje</b>
            <Table striped bordered hover>
              <thead>
                <tr key={250}>
                  <th width="50%">Vencimento</th>
                  <th width="50%">$Total</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aPenMesAnt.map((oVenda, index) => (
                  <>
                    <tr key={(index+60000)}>
                      <td>{FormataData(oVenda.data_vencim)}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.valor))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
            <b>Total de parcelas em aberto até a data de hoje: {FormataValor(parseFloat(this.state.aTotPeMAnt[0]))}</b>
            <br/><br/>
            </>
        }
        { /* comentado pois parte do valor das vendas de um período já aparece nas parcelas em aberto 
        <br/>
        <b>Total Geral: {FormataValor(parseFloat(this.state.aVendasTot[0] + this.state.aTotPeMAnt[0]))}</b>
        */ }
      </>
    );
  }
}

export default VendasRelFechRes;