import React, { Component } from "react";
import CabPage from "../CabPage.js";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import axios from "axios";
import { CurDate } from "../k_functions.js";

class ListarMovEst extends Component {
  state = {
    validated: false,
    dIni: CurDate("YMD"),   // data de hoje
    dFim: CurDate("YMD"),   // data de hoje
  };

  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let isValid = ! (form.checkValidity() === false);
    if (! isValid)
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 

    // se os dados são válidos 
    if (isValid)
      this.setState({validated: true});
  };

  handleChange_dIni = () => e => {  
      let dIni = e.target.value;
      this.setState({ dIni });
  }; 

  handleChange_dFim = () => e => {  
    let dFim = e.target.value;
    this.setState({ dFim });
  };

  render() {
    return (
      <>
        <CabPage titulo="Listar Movimentações" paginavoltar="/home" />
        <div className="App-body">
          <form validated={this.validated} onSubmit={this.handleSubmit} action="/estlisres">
            <Row>
              <Col>
                <FloatingLabel controlId="floatingDatIni" label="Data Inicial">
                <Form.Control required type="date" name="DatIni" defaultValue={this.state.dIni} onChange={this.handleChange_dIni} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingDatFim" label="Data Final">
                  <Form.Control required type="date" name="DatFim" defaultValue={this.state.dFim} onChange={this.handleChange_dFim} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingVenIni" label="Venda Inicial">
                <Form.Control required type="number" defaultValue="0" name="VenIni" min="0" max="999999" onChange={this.handleChange_vIni} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingVenFim" label="Venda Final">
                  <Form.Control required type="number" defaultValue="999999" name="VenFim" min="0" max="999999" onChange={this.handleChange_vFim} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <center>
              <Button as="button" type="submit" id="bt_add" 
                variant="success" size="lg">Listar
              </Button>
            </center>
          </form>
        </div>
      </>
    )
  }
}

export default ListarMovEst;