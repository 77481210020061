import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import { trataAxios } from "../k_functions.js";

class LancaEstoqueRet extends Component {
  state = {
    validated: false,
    aEstRetirar: [],    // contém a lista de itens a entregar, filtrada pela venda
    sCarregador: "",    // nome do carregador
  };

  /* requisição com axios para ler materiais a retirar de uma certa venda */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Estoque/ARetirar.php",
        func_trata: "EstoqueARetirar"
      }
    })
    .then((response) => {
      let aEstRetirar = response.data;
      if (response.data.msg != 'ERRO')
        aEstRetirar = response.data.filter(el => el.id_venda === (this.props.idVenda * 1));
      this.setState({aEstRetirar: aEstRetirar});
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  handleChangeCarreg = () => e => {  
//    console.log (e);
    let sCarregador = e.target.value;
    this.setState({ sCarregador });
  };

  handleSubmit = (event) => {

    let tem_qtd_dif_0 = false; // tem alguma qtd lançada diferente de zero ?

    // primeiro valida o formulario
    const form = event.currentTarget;
//    console.log(this.state.aEstRetirar);
    this.state.aEstRetirar.map((oitemRet, idx) => (tem_qtd_dif_0 = (oitemRet.qtde !== 0 || tem_qtd_dif_0)));
//    console.log(tem_qtd_dif_0);
    let isValid = ! (form.checkValidity() === false);
    if (! tem_qtd_dif_0)
    {
      isValid = false;
      alert("Pelo menos uma das quantidades deve ser diferente de zero.")
    }
    if ((! isValid) || (! window.confirm("Confirma a retirada ?")))
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 
    this.setState({validated: true});

    // se os dados são válidos e confirmou a venda
    if (isValid)
    {
      // chama o programa php para salvar os dados
      const dadosRet = {
        nusu: 1,
        carr: this.state.sCarregador,
        idve: this.props.idVenda, 
        iret: JSON.stringify(this.state.aEstRetirar),
        prog_trata: "./Estoque/ARetirarSalvar.php",
        func_trata: "SalvaRetirada"
      }
      trataAxios(dadosRet);
    }
  };

  handleChange = idx => e => {
    const { name, value } = e.target;
    /*
    console.log (e.target);
    console.log (e);
    console.log (name);
    console.log (value);
    */
    // cria uma nova linha como cópia da atual
    let aItem = this.state.aEstRetirar[idx];
//    console.log(aItem);
    // altera na nova linha o valor do campo editado pelo usuário
    aItem = {...aItem, [name]: value};
    // cria uma cópia das linhas q estão em state
    let aEstRetirar = [...this.state.aEstRetirar];
    // substitiu a linha idx no novo array de itens a retirar pela linha atualizada
    aEstRetirar[idx] = aItem;
    // atualiza o aItensRet do state
    this.setState({
      aEstRetirar
    });
//    console.log(aEstRetirar);
  };
    
  render() {
    return (
      <>
        <CabPage titulo="Lançar Retirada" paginavoltar="/estret" />
        <div className="App-body">
          {this.state.aEstRetirar.msg === 'ERRO' && 
            <Alert variant="warning">Não há mais pendências{
              this.props.idVenda !== 0 ? " na venda " + this.props.idVenda : ""} !</Alert>
          }
          {this.state.aEstRetirar.length > 0 && (
            <>
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingClientes" label="Cliente">
                    <Form.Control type="text" value={this.state.aEstRetirar[0].nome_razao} disabled/>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingVenda" label="Venda">
                      <Form.Control type="number" value={this.state.aEstRetirar[0].id_venda} disabled/>
                    </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <form validated={this.validated} onSubmit={this.handleSubmit} >
                <div className="carrinho">
                  <Table striped bordered hover size="sm">
                      <thead>
                          <tr align='center'>
                              <th width="30%">Produto</th>
                              <th width="20%">Und</th>
                              <th width="20%">Saldo</th>
                              <th width="30%">Retirar</th>
                          </tr>
                      </thead>
                      <tbody>
                          {this.state.aEstRetirar.map((oItemRet, idx) => (
                            <tr key={idx} align='center'>
                              <td align='left'>{oItemRet.desc_produto}</td>
                              <td>{oItemRet.desc_unidade}</td>
                              <td><b className="txt_destac">{oItemRet.qtd_pendente}</b></td>
                              <td>
                                <Form.Group as={Col} md="4" controlId={"validationCustom"}>
                                  <Form.Control required type="number" name="qtde" min="0.01" step=".01"
                                    max={oItemRet.qtd_pendente} onChange={this.handleChange(idx)}
                                  />
                                </Form.Group>                              
                              </td>
                            </tr>
                          ))}
                      </tbody>
                  </Table>
                </div>
                <Form.Group controlId={"validationCustomCarreg"}>
                <Form.Control as="textarea" parcelas={3} placeholder="Carregador"
                  value={this.state.sCarregador}
                  onChange={this.handleChangeCarreg()}
                  />
                </Form.Group><br/>
                <center>
                  <Button as="button" type="submit" id="bt_add" 
                    variant="success" size="lg">Lançar retirada
                  </Button>
                </center>
              </form>
            </>
          )}
        </div>
      </>
    );
  }
}

export default LancaEstoqueRet;