import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CabPage from "./CabPage";
import { AppVer, UserLogged } from "./k_functions.js";

const Home = () => {
  let acessofull = localStorage.getItem("acessofull");
  return (
    <>
      <CabPage titulo="Home" paginavoltar="/" />
      <div className="App-body">
        <br/>
        <Container>
          {acessofull === 'R' && 
            <Row>
              <Col>
                <DropdownButton id="dropdown-basic-button2" title="Estoque"  variant="warning" size="lg">
                  <Dropdown.Item href="/estret">A Retirar</Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
          }
            <Row>
              <Col>
              {acessofull !== 'R' && 
                <DropdownButton id="dropdown-basic-button1" title="Vendas" variant="warning" size="lg">
                  <Dropdown.Item href={"/vender"}>Vender</Dropdown.Item>
                  <Dropdown.Item href="/venlis">Listar</Dropdown.Item>
                  <Dropdown.Item href="/venl10">Últimas 10</Dropdown.Item>
                  {acessofull === 'S' && <Dropdown.Item href="/venrel">Relatório de Vendas</Dropdown.Item>}
                  {acessofull === 'S' && <Dropdown.Item href="/venrfe">Relatório de Fechamento</Dropdown.Item>}
                  <Dropdown.Item href="/venchk">Checar</Dropdown.Item>
                </DropdownButton>
              }
              </Col>
              <Col>
              {acessofull !== 'R' && 
                <DropdownButton id="dropdown-basic-button2" title="Estoque"  variant="warning" size="lg">
                  <Dropdown.Item href="/estmov">Movimentar</Dropdown.Item>
                  <Dropdown.Item href="/estsit">Situação</Dropdown.Item>
                  <Dropdown.Item href="/estret">A Retirar</Dropdown.Item>
                  <Dropdown.Item href="/estlis">Listar Movimentações</Dropdown.Item>
                </DropdownButton>
              }
              </Col>
            </Row>
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <Row>
              <Col>
                {acessofull !== 'R' && 
                  <DropdownButton id="dropdown-basic-button3" title="Cadastros"  variant="warning" size="lg">
                    <Dropdown.Item href="/clilis">Clientes</Dropdown.Item>
                    <Dropdown.Item href="/cliimp">Importar clientes do RedID</Dropdown.Item>
                    <Dropdown.Item href="/prelis">Preços</Dropdown.Item>
                    <Dropdown.Item href="/forlis">Formas Pgto</Dropdown.Item>
                    <Dropdown.Item href="/prolis">Produtos</Dropdown.Item>
                    <Dropdown.Item href="/unilis">Unidades</Dropdown.Item>
                    <Dropdown.Item href="/usulis">Usuários</Dropdown.Item>
                  </DropdownButton>
                }
              </Col>
              <Col>
              {acessofull !== 'R' && 
                <DropdownButton id="dropdown-basic-button4" title="Finanças"  variant="warning" size="lg">
                  <Dropdown.Item href="/finsel/qui">Quitar</Dropdown.Item>
                  {acessofull === 'S' && <Dropdown.Item href="/finsel/lis">Listar Parcelas</Dropdown.Item>}
                  {acessofull === 'S' && <Dropdown.Item href="/finrel">Relatório</Dropdown.Item>}
                </DropdownButton>
              }
              </Col>
            </Row>
        </Container>
        <br/><br/>
        <UserLogged />
        <AppVer />
      </div>
    </>
  );
}

export default Home;

//                 <Dropdown.Item href="/venlisA">Em Andamento</Dropdown.Item>
