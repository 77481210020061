import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import { FormataValor, FormataData } from "../k_functions.js";

function TopTabRelVen(k)
{
  return (
    <thead key={3*k+1}>
      <tr key={k}>
        <th width="35%">Produto</th>
        <th width="25%">Unid</th>
        <th width="10%">Q&nbsp;Ven<br/>Q&nbsp;Bon</th>
        <th width="15%">$Total</th>
        <th width="15%">$Medio</th>
      </tr>
    </thead>
  )
}

class VendasRelRes extends Component {
  state = {
    aVendasCli: [], // total de vendas por cliente
    aVendasPro: [], // total de vendas por produto e unidade
    aVendasCPU: [], // total de vendas por cliente, produto e unidade
    aVendasDia: [], // total de vendas por dia
    aVendasDPU: [], // total de vendas por dia, produto e unidade
    aVendasA_M: [], // total de vendas por ano/mês
    aVendasTot: [], // total de vendas conforme filtros selecionados
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
//    console.log(this.props);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Vendas/VendasRelat.php",
        func_trata: "VendasRelat",
        dat_ini: this.props.dIni,
        dat_fim: this.props.dFim,
        cod_pro: this.props.cPro,
        cod_cli: this.props.cCli,
      }
    })
    .then((response) => {
      //alert(response.data);
      this.setState({ aVendasCli: response.data[0] });
      this.setState({ aVendasPro: response.data[1] });
      this.setState({ aVendasCPU: response.data[2] });
      this.setState({ aVendasDia: response.data[3] });
      this.setState({ aVendasDPU: response.data[4] });
      this.setState({ aVendasA_M: response.data[5] });
      this.setState({ aVendasTot: response.data[6] });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  render() {
    // console.log(this.state.aVendasCli.shift());
//    console.log(this.props);
    return (
      <>
        <CabPage  titulo="Relatório de Vendas" 
                  subtitulo={"Período das vendas: " + FormataData(this.props.dIni) + " - " + FormataData(this.props.dFim)}
                  paginavoltar="/venrel" 
                  classSubTit="SubTitRel" />
        {this.state.aVendasCli.length === 0 && 
          <Alert variant="warning">Não há vendas para listar !</Alert>
        }
        {this.state.aVendasCli.length > 0 && this.props.cTCl === "S" && 
          <>
            <b>Total por Cliente</b>
            <Table striped bordered hover>
              <thead>
                <tr key={100}>
                  <th width="50%">Cliente</th>
                  <th width="50%">$Total</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aVendasCli.map((oVenda, index) => (
                  <>
                    <tr key={3000+index}>
                      <td>{oVenda.fantasia} ({oVenda.id_cliente})</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aVendasCli.length > 0 && this.props.cTPr === "S" && 
          <>
            <b>Total por Produto e Unidade</b>
            <Table striped bordered hover>
              <TopTabRelVen k={50} />
              <tbody>
              {
                this.state.aVendasPro.map((oVenda, index) => (
                  <>
                    <tr key={4000+index}>
                      <td>{oVenda.desc_produto}</td>
                      <td>{oVenda.desc_unidade}</td>
                      <td align='right'>
                        {FormataValor(parseFloat(oVenda.QtdVen))}<br/>
                        {FormataValor(parseFloat(oVenda.QtdBon))}
                      </td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen))}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen / oVenda.QtdVen))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aVendasCli.length > 0 && this.props.cTCP === "S" && 
          <>
            <b>Total por Cliente, Produto e Unidade</b>
            <Table striped bordered hover>
              <TopTabRelVen k={600} />
              <tbody>
              {
                  this.state.aVendasCPU.map((oVenda, index) => (
                  <>
                    {(index === 0 || oVenda.id_cliente !== this.state.aVendasCPU[index-1].id_cliente) ? 
                      <>
                        {index > 0 ? <tr key={10000+index}><td colSpan={5}>{/*Total: {1} */}</td></tr> : ""}
                        <tr key={1000+index}><td colSpan={5}><b>{oVenda.fantasia} ({oVenda.id_cliente})</b></td></tr>
                      </> 
                    : ""}
                    <tr key={2000+index}>
                      <td>{oVenda.desc_produto}</td>
                      <td>{oVenda.desc_unidade}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.QtdVen))}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen))}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen / oVenda.QtdVen))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aVendasCli.length > 0 && this.props.cTDi === "S" && 
          <>
            <b>Total por Dia</b>
            <Table striped bordered hover>
              <thead>
                <tr key={10}>
                  <th width="50%">Data</th>
                  <th width="50%">$Total</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aVendasDia.map((oVenda, index) => (
                  <>
                    <tr key={(index+4000)}>
                      <td>{FormataData(oVenda.data_venda)}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aVendasCli.length > 0 && this.props.cTDP === "S" && 
          <>
            <b>Total por Dia, Produto e Unidade</b>
            <Table striped bordered hover>
              <thead>
                <tr key={200}>
                  <th width="10%">Data</th>
                  <th width="40%">Produto</th>
                  <th width="10%">Unidade</th>
                  <th width="10%">Qtd</th>
                  <th width="10%">$Unit</th>
                  <th width="20%">$Total</th>
                </tr>
              </thead>
              <tbody>
              {
                  this.state.aVendasDPU.map((oVenda, index) => (
                  <>
                    <tr key={20000+index}>
                      <td>{FormataData(oVenda.data_venda)}</td>
                      <td>{oVenda.desc_produto}</td>
                      <td>{oVenda.desc_unidade}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.QtdVen))}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen / oVenda.QtdVen))}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aVendasCli.length > 0 && this.props.cTAM === "S" && 
          <>
            <b>Total por Ano/Mês</b>
            <Table striped bordered hover>
              <thead>
                <tr key={150}>
                  <th width="50%">Ano/Mês</th>
                  <th width="50%">$Total</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aVendasA_M.map((oVenda, index) => (
                  <>
                    <tr key={(index+50000)}>
                      <td>{oVenda.ano_mes_venda}</td>
                      <td align='right'>{FormataValor(parseFloat(oVenda.ValVen))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        <br/>
        <b>Total Geral: {FormataValor(parseFloat(this.state.aVendasTot[0]))}</b>
      </>
    );
  }
}

export default VendasRelRes;